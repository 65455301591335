export const PrivacyUrlViaLocaleSelector = [{
    "brandID":0,
    "CountryWiseURL" : [{
        "countryCode": "AT",
        "privacyUrl": "https://www.hm.com/setlocation?location=at&lang=de&name=Austria&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "AU",
        "privacyUrl": "https://www.hm.com/setlocation?location=au&lang=en&name=Australia&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "BE",
        "privacyUrl": "https://www.hm.com/setlocation?location=be&lang=nl&name=Belgium&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "BG",
        "privacyUrl": "https://www.hm.com/setlocation?location=bg&lang=bg&name=Bulgaria&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "CA",
        "brandLanguages" : ['en','fr'],
        "privacyUrl": "https://www.hm.com/setlocation?location=ca&lang=en&name=Canada&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "CH",
        "brandLanguages" : ['de','fr','it'],
        "privacyUrl": "https://www.hm.com/setlocation?location=ch&lang=de&name=Switzerland&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "CN",
        "privacyUrl": "https://www.hm.com/setlocation?location=cn&lang=en&name=China%E2%80%99s+Mainland&orguri=/customer-service/legal-and-privacy/privacy-link"
    },
    {
        "countryCode": "CY",
        "privacyUrl": "https://www.hm.com/setlocation?location=cy&lang=en&name=Cyprus&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "CZ",
        "privacyUrl": "https://www.hm.com/setlocation?location=cz&lang=cs&name=Czech+Republic&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "DE",
        "privacyUrl": "https://www.hm.com/setlocation?location=de&lang=de&name=Germany&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "DK",
        "privacyUrl": "https://www.hm.com/setlocation?location=dk&lang=da&name=Denmark&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "EE",
        "privacyUrl": "https://www.hm.com/setlocation?location=ee&lang=et&name=Estonia&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "ES",
        "privacyUrl": "https://www.hm.com/setlocation?location=es&lang=es&name=Spain&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "FI",
        "brandLanguages" : ['en','fi'],
        "privacyUrl": "https://www.hm.com/setlocation?location=fi&lang=sv&name=Finland&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "FR",
        "privacyUrl": "https://www.hm.com/setlocation?location=fr&lang=fr&name=France&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "GB",
        "privacyUrl": "https://www.hm.com/setlocation?location=gb&lang=en&name=United+Kingdom&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "GR",
        "privacyUrl": "https://www.hm.com/setlocation?location=gr&lang=el&name=Greece&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "HK",
        "privacyUrl": "https://www.hm.com/setlocation?location=hk&lang=en&name=Hong+Kong&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "HR",
        "privacyUrl": "https://www.hm.com/setlocation?location=hr&lang=hr&name=Croatia&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "HU",
        "privacyUrl": "https://www.hm.com/setlocation?location=hu&lang=hu&name=Hungary&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "IE",
        "privacyUrl": "https://www.hm.com/setlocation?location=ie&lang=en&name=Ireland&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "IT",
        "privacyUrl": "https://www.hm.com/setlocation?location=it&lang=it&name=Italy&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "JP",
        "privacyUrl": "https://www.hm.com/setlocation?location=jp&lang=ja&name=Japan&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "KR",
        "privacyUrl": "https://www.hm.com/setlocation?location=kr&lang=ko&name=South+Korea&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "LU",
        "privacyUrl": "https://www.hm.com/setlocation?location=lu&lang=fr&name=Luxembourg&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "LV",
        "privacyUrl": "https://www.hm.com/setlocation?location=lv&lang=lv&name=Latvia&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "MO",
        "brandLanguages" : ['en','zh'],
        "privacyUrl": "https://www.hm.com/setlocation?location=mo&lang=en&name=Macau&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "MX",
        "privacyUrl": "https://www.hm.com/setlocation?location=mx&lang=es&name=Mexico&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "MY",
        "privacyUrl": "https://www.hm.com/setlocation?location=my&lang=en&name=Malaysia&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "NL",
        "privacyUrl": "https://www.hm.com/setlocation?location=nl&lang=nl&name=Netherlands&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "NO",
        "privacyUrl": "https://www.hm.com/setlocation?location=no&lang=no&name=Norway&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "PH",
        "privacyUrl": "https://www.hm.com/setlocation?location=ph&lang=en&name=Philippines&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "PL",
        "privacyUrl": "https://www.hm.com/setlocation?location=pl&lang=pl&name=Poland&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "PT",
        "privacyUrl": "https://www.hm.com/setlocation?location=pt&lang=pt&name=Portugal&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "RO",
        "privacyUrl": "https://www.hm.com/setlocation?location=ro&lang=ro&name=Romania&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "RU",
        "privacyUrl": "https://www.hm.com/setlocation?location=ru&lang=ru&name=Russia&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "SE",
        "privacyUrl": "https://www.hm.com/setlocation?location=se&lang=sv&name=Sweden&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "SG",
        "privacyUrl": "https://www.hm.com/setlocation?location=sg&lang=en&name=Singapore&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "SI",
        "privacyUrl": "https://www.hm.com/setlocation?location=si&lang=sl&name=Slovenia&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "TR",
        "privacyUrl": "https://www.hm.com/setlocation?location=tr&lang=tr&name=Turkey&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "TW",
        "brandLanguages" : ['en','zh'],
        "privacyUrl": "https://www.hm.com/setlocation?location=tw&lang=en&name=Taiwan+Region&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "US",
        "privacyUrl": "https://www.hm.com/setlocation?location=us&lang=en&name=United+States&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "LT",
        "privacyUrl": "https://www.hm.com/setlocation?location=lt&lang=lt&name=Lithuania&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "IN",
        "privacyUrl": "https://www.hm.com/setlocation?location=in&lang=en&name=India&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "SK",
        "privacyUrl": "https://www.hm.com/setlocation?location=sk&lang=sk&name=Slovakia&orguri=/customer-service/legal-and-privacy/privacy-link.html"
    },
    {
        "countryCode": "RS",
        "privacyUrl": "https://www2.hm.com/sr_rs/customer-service/pravo-i-privatnost.html"
    },
    {
        "countryCode": "VN",
        "privacyUrl": "https://int-www2.hm.com/vi_vn/customer-service/legal-and-privacy/privacy-link.html"
    }
]},
{
    "brandID":3,
    "CountryWiseURL" : [{
        "countryCode": "AT",
        "brandLanguages" : ['en','de'],
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.de_AT.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "BE",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.fr_BE.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "BG",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.bg_BG.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "CH",
        "brandLanguages" : ['en','de'],
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.en_CH.en_ch.CHF.jsp?goeorguri=/en_ch/help/help/privacy-notice.html"
    },
    {
        "countryCode": "CY",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.el_CY.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "CZ",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.cs_CZ.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "DE",
        "brandLanguages" : ['en','de'],
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.de_DE.en_de.EUR.jsp?goeorguri=/en_de/help/help/privacy-notice.html"
    },
    {
        "countryCode": "DK",
        "privacyUrl": "https://www.weekday.com/en-dk/legal/privacy-notice/"
    },
    {
        "countryCode": "EE",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.et_EE.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "ES",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.es_ES.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "FI",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.fi_FI.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "FR",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.fr_FR.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "GB",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.en_GB.en_gbp.GBP.jsp?goeorguri=/en_gbp/help/help/privacy-notice.html"
    },
    {
        "countryCode": "GR",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.el_GR.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "HR",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.hr_HR.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "HU",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.hu_HU.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "IE",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.en_IE.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "IT",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.it_IT.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "LT",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.lt_LT.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "LU",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.fr_LU.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "LV",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.lv_LV.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "NL",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.nl_NL.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "NO",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.no_NO.en_nok.NOK.jsp?goeorguri=/en_nok/help/help/privacy-notice.html"
    },
    {
        "countryCode": "PL",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.pl_PL.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "PT",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.pt_PT.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "RO",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.ro_RO.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "RU",
        "privacyUrl": "https://www.weekday.com/ru_ru/help/privacy-notice/"
    },
    {
        "countryCode": "SE",
        "brandLanguages" : ['en','sv'],
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.sv_SE.en_sek.SEK.jsp?goeorguri=/en_sek/help/help/privacy-notice.html"
    },
    {
        "countryCode": "SI",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.sl_SI.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "SK",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.sk_SK.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    },
    {
        "countryCode": "defaultGlobal",
        "privacyUrl": "https://www.weekday.com/content/weekday/page.countryselector.en_WW.en_eur.EUR.jsp?goeorguri=/en_eur/help/help/privacy-notice.html"
    }
]},
{
    "brandID":2,
    "CountryWiseURL" : [{
        "countryCode": "AT",
        "brandLanguages" : ['en','de'],
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.de_AT.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "BE",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.fr_BE.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "BG",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.bg_BG.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "CH",
        "brandLanguages" : ['en','de'],
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.en_CH.en_ch.CHF.jsp?goeorguri=/en_ch/privacy-notice.html"
    },
    {
        "countryCode": "CY",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.el_CY.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "CZ",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.cs_CZ.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "DE",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.de_DE.en_de.EUR.jsp?goeorguri=/en_de/privacy-notice.html"
    },
    {
        "countryCode": "DK",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.en_DK.en_dkk.DKK.jsp?goeorguri=/en_dkk/privacy-notice.html"
    },
    {
        "countryCode": "EE",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.et_EE.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "ES",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.es_ES.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "FI",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.fi_FI.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "FR",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.fr_FR.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "GB",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.en_GB.en_gbp.GBP.jsp?goeorguri=/en_gbp/privacy-notice.html"
    },
    {
        "countryCode": "GR",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.el_GR.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "HR",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.hr_HR.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "HU",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.hu_HU.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "IE",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.en_IE.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "IT",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.it_IT.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "LT",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.lt_LT.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "LU",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.fr_LU.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "LV",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.lv_LV.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "NL",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.nl_NL.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "NO",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.no_NO.en_nok.NOK.jsp?goeorguri=/en_nok/privacy-notice.html"
    },
    {
        "countryCode": "PL",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.pl_PL.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "PT",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.pt_PT.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "RO",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.ro_RO.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "RU",
        "privacyUrl": "https://www.monki.com/ru_ru/privacy-notice/"
    },
    {
        "countryCode": "SE",
        "brandLanguages" : ['en','sv'],
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.sv_SE.en_sek.SEK.jsp?goeorguri=/en_sek/privacy-notice.html"
    },
    {
        "countryCode": "SI",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.sl_SI.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "SK",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.sk_SK.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    },
    {
        "countryCode": "defaultGlobal",
        "privacyUrl": "https://www.monki.com/content/monki/page.countryselector.en_WW.en_eur.EUR.jsp?goeorguri=/en_eur/privacy-notice.html"
    }
    ]},
    {
        "brandID":6,
        "CountryWiseURL" : [
        {
            "countryCode": "AT",
            "brandLanguages" : ['en','de'],
            "privacyUrl": "https://www.arket.com/de-at/customer-service/legal/privacy-notice/"
        },
        {
            "countryCode": "BE",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.fr_BE.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "BG",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.bg_BG.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "CH",
            "brandLanguages" : ['en','de'],
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.en_CH.en_ch.CHF.jsp?goeorguri=/en_ch/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "CY",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.el_CY.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "CZ",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.cs_CZ.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "DE",
            // "brandLanguages" : ['en','de'],
            "privacyUrl": "https://www.arket.com/de-de/customer-service/legal/privacy-notice/"
        },
        {
            "countryCode": "DK",
            "privacyUrl": "https://www.arket.com/en-dk/customer-service/legal/privacy-notice/"
        },
        {
            "countryCode": "EE",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.et_EE.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "ES",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.es_ES.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "FI",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.fi_FI.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "FR",
            "privacyUrl": "https://www.arket.com/fr-fr/customer-service/legal/privacy-notice/"
        },
        {
            "countryCode": "GB",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.en_GB.en_gbp.GBP.jsp?goeorguri=/en_gbp/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "GR",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.el_GR.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "HR",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.hr_HR.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "HU",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.hu_HU.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "IE",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.en_IE.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "IT",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.it_IT.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "LT",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.lt_LT.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "LU",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.fr_LU.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "LV",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.lv_LV.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "NL",
            "privacyUrl": "https://www.arket.com/en-nl/customer-service/legal/privacy-notice/"
        },
        {
            "countryCode": "NO",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.no_NO.en_nok.NOK.jsp?goeorguri=/en_nok/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "PL",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.pl_PL.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "PT",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.pt_PT.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "RO",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.ro_RO.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "RU",
            "privacyUrl": "https://www.arket.com/ru_ru/customer-service/privacy-notice/"
        },
        {
            "countryCode": "SE",
            "brandLanguages": ['en', 'sv'],  
            "languageMapping": {  
                "sv": {
                    "url": "https://www.arket.com/sv-se/customer-service/legal/privacy-notice/"
                },
                "en": {
                    "url": "https://www.arket.com/en-se/customer-service/legal/privacy-notice/"
                }
            }
        },
        {
            "countryCode": "SI",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.sl_SI.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "SK",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.sk_SK.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "defaultGlobal",
            "privacyUrl": "https://www.arket.com/content/p11/page.countryselector.en_WW.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        }
    ]},
    {
        "brandID":1,
            "CountryWiseURL" : [
        {
            "countryCode": "AT",
            "brandLanguages" : ['en','de'],
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.de_AT.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "AU",
            "privacyUrl": "https://www.cos.com/en-au/customer-service/privacy-notice"
        },
        {
            "countryCode": "BE",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.fr_BE.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "BG",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.bg_BG.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "CH",
            "brandLanguages" : ['en','de','fr'],
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.en_CH.en_ch.CHF.jsp?goeorguri=/en_ch/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "CY",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.el_CY.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "CZ",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.cs_CZ.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "DE",
            "brandLanguages": ['en', 'de'],  
            "languageMapping": {  
                "de": {
                    "url": "https://www.cos.com/de-de/customer-service/privacy-notice"
                },
                "en": {
                    "url": "https://www.cos.com/en-de/customer-service/privacy-notice"
                }
            }
        },
        {
            "countryCode": "DK",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.da_DK.en_dkk.DKK.jsp?goeorguri=/en_dkk/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "EE",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.et_EE.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "ES",
            "privacyUrl": "https://www.cos.com/es-es/customer-service/privacy-notice"
        },
        {
            "countryCode": "FI",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.fi_FI.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "FR",
            "privacyUrl": "https://www.cos.com/fr-fr/customer-service/privacy-notice"
        },
        {
            "countryCode": "GB",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.en_GB.en_gbp.GBP.jsp?goeorguri=/en_gbp/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "GR",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.el_GR.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "HR",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.hr_HR.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "HU",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.hu_HU.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "IE",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.en_IE.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "IT",
            "privacyUrl": "https://www.cos.com/it-it/customer-service/privacy-notice"
        },
        {
            "countryCode": "LT",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.lt_LT.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "LU",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.fr_LU.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "LV",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.lv_LV.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "NL",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.nl_NL.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "NO",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.no_NO.en_nok.NOK.jsp?goeorguri=/en_nok/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "PL",
            "privacyUrl": "https://www.cos.com/pl-pl/customer-service/privacy-notice"
        },
        {
            "countryCode": "PT",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.pt_PT.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "RO",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.ro_RO.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "RU",
            "privacyUrl": "https://www.cosstores.com/ru_ru/customer-service/privacy-notice/"
        },
        {
            "countryCode": "SE",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.sv_SE.en_sek.SEK.jsp?goeorguri=/en_sek/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "SI",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.sl_SI.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "SK",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.sk_SK.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "US",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.en_US.en_usd.USD.jsp?goeorguri=/en_usd/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "defaultGlobal",
            "privacyUrl": "https://www.cosstores.com/content/cos/page.countryselector.en_WW.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        }
    ]},
    {
    
        "brandID":5,
        "CountryWiseURL" : [
        {
            "countryCode": "AT",
            "brandLanguages" : ['en','de'],
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.de_AT.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "BE",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.fr_BE.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "BG",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.bg_BG.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "CH",
            "brandLanguages" : ['en','de'],
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.en_CH.en_ch.CHF.jsp?goeorguri=/en_ch/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "CY",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.el_CY.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "CZ",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.cs_CZ.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "DE",
            "brandLanguages": ['en', 'de'],  
            "languageMapping": {  
                "de": {
                    "url": "https://www.stories.com/de-de/customer-service/privacy-notice"
                },
                "en": {
                    "url": "https://www.stories.com/en-de/customer-service/privacy-notice"
                }
            }
        },
        {
            "countryCode": "DK",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.da_DK.en_eur.DKK.jsp?goeorguri=/en_dkk/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "EE",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.et_EE.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "ES",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.es_ES.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "FI",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.fi_FI.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "FR",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.fr_FR.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "GB",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.en_GB.en_gbp.GBP.jsp?goeorguri=/en_gbp/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "GR",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.el_GR.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "HR",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.hr_HR.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "HU",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.hu_HU.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "IE",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.en_IE.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "IT",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.it_IT.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "LT",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.lt_LT.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "LU",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.fr_LU.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "LV",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.lv_LV.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "NL",
            "privacyUrl": "https://www.stories.com/en-nl/customer-service/privacy-notice/"
        },
        {
            "countryCode": "NO",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.no_NO.en_nok.NOK.jsp?goeorguri=/en_nok/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "PL",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.pl_PL.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "PT",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.pt_PT.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "RO",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.ro_RO.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "RU",
            "privacyUrl": "https://www.stories.com/ru_ru/customer-service/privacy-notice/"
        },
        {
            "countryCode": "SE",
            "brandLanguages": ['en', 'sv'],  
            "languageMapping": {  
                "sv": {
                    "url": "https://www.stories.com/sv-se/customer-service/privacy-notice"
                },
                "en": {
                    "url": "https://www.stories.com/en-se/customer-service/privacy-notice"
                }
            }
        },
        {
            "countryCode": "SI",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.sl_SI.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "SK",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.sk_SK.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "US",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.en_US.en_usd.USD.jsp?goeorguri=/en_usd/customer-service/privacy-notice.html"
        },
        {
            "countryCode": "defaultGlobal",
            "privacyUrl": "https://www.stories.com/content/stories/page.countryselector.en_WW.en_eur.EUR.jsp?goeorguri=/en_eur/customer-service/privacy-notice.html"
        }
    ]}

]